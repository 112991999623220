@import "../../variables.scss";

.next-show {
  background-color: $darkBackgroundColour;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;

  width: 350px;
  color: rgb(131, 87, 46);

  h1 {
    text-transform: uppercase;
    font-weight: 500;
  }

  span {
    font-size: 1.5rem;
  }
}

@import "../../variables.scss";
.nav {
  background-color: $darkBackgroundColour;
  width: 230px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 96px;
  padding: 10px 16px;
  border-right: 1px solid #ddd;
  overflow: hidden;
  transition: 300ms ease-in-out;

  &__item {
    color: $darkGreyText;
    text-decoration: none;
    font-size: 1.2rem;
    transition: 300ms;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    padding: 20px 5px;
    padding-left: 20px;
    background-color: $backgroundColour;
    text-transform: uppercase;
    &:hover {
      font-weight: 700;
    }

    &--project {
      // background-color: rgba(200, 200, 200, 0.2);
    }
  }

  &--active {
    font-weight: 700;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }
}

@media (max-width: 900px) {
  .nav {
    position: relative;
    height: fit-content;
    width: 100%;
    padding: 0;
    background-color: $backgroundColour;

    &__item {
      padding-left: 30px;
    }
  }
}

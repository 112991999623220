.reviews-page {
  text-align: center;

  &__review {
    margin-bottom: 20px;

    &__text {
      font-size: 1.3rem;
    }
  }
}

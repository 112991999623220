.footer {
  background-color: white;
  color: black;
  display: flex;
  justify-content: center;
  padding: 10px;
  position: relative;
  margin-top: 60px;
  border-top: 1px solid #ddd;
}

@import url(https://fonts.googleapis.com/css2?family=Dosis:wght@200;400;500;700&display=swap);
body {
  margin: 0;
  /* font-family: "Dosis", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

body {
  font-family: "Dosis", sans-serif;
}

html {
  scroll-behavior: smooth;
  scroll-padding: 20px;
}

.slider-slide {
  width: 100vw;
  height: 50vh;
  overflow: hidden;
  position: relative;
  opacity: 0.8;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.slider-slide:hover {
  opacity: 1;
  transition: 0.3s ease-in-out;
}

.active {
  opacity: 1;
  transition: 0.3s ease-in-out;
}

/* img {
  width: 100%;
  max-height: 100vh;
} */

.btn {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10%;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.leftBtn {
  left: 0;
  right: auto;
}

.rightBtn {
  left: auto;
  right: 0;
}

.tab-container {
  display: flex;
  flex-direction: column;
  -webkit-animation: fade 1s ease-in-out;
          animation: fade 1s ease-in-out;
}

@-webkit-keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

body{color:#323232;background-color:#f3ebd6}p{font-size:1.1rem}embed,iframe{background-color:white}.wrapper{position:relative}.app-container{display:flex}.page-container{flex:1 1;margin-top:76px;margin-left:250px;padding:30px}.page{padding:40px;padding-top:10px;display:flex;flex-direction:column;align-items:center;-webkit-animation:fadeIn 1s ease-in-out;animation:fadeIn 1s ease-in-out;-webkit-animation-fill-mode:both;animation-fill-mode:both;min-height:100vh;max-width:1200px}@media (max-width: 900px){.page-container{margin-left:0}.app-container{display:block}}@media (max-width: 700px){.page{padding:1px}}.youtube{width:80%;aspect-ratio:16/9}@-webkit-keyframes fadeIn{from{opacity:0}to{opacity:1}}@keyframes fadeIn{from{opacity:0}to{opacity:1}}

.header{display:flex;justify-content:center;align-items:center;box-shadow:2px 2px 10px rgba(0,0,0,0.1);position:fixed;left:0;right:0;height:96px;background-color:#dacdad;z-index:2}.header__link{text-decoration:none;color:#323232;text-transform:uppercase;font-size:23px}@media (max-width: 800px){.header__link{font-size:14px}}.menu-toggle{position:absolute;left:10px;top:23px;width:40px;height:50%;display:flex;flex-direction:column;justify-content:space-evenly;background-color:transparent;border:none;cursor:pointer}.menu-toggle__line{width:100%;height:1px;background-color:black}@media (min-width: 901px){.menu-toggle{display:none}}

.nav{background-color:#dacdad;width:230px;display:flex;flex-direction:column;height:100vh;position:fixed;left:0;top:96px;padding:10px 16px;border-right:1px solid #ddd;overflow:hidden;transition:300ms ease-in-out}.nav__item{color:#323232;text-decoration:none;font-size:1.2rem;transition:300ms;border-bottom:1px solid #eee;display:flex;align-items:center;padding:20px 5px;padding-left:20px;background-color:#f3ebd6;text-transform:uppercase}.nav__item:hover{font-weight:700}.nav--active{font-weight:700;border-top:1px solid #ddd;border-bottom:1px solid #ddd}@media (max-width: 900px){.nav{position:relative;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;width:100%;padding:0;background-color:#f3ebd6}.nav__item{padding-left:30px}}

.home-page{display:flex;flex-direction:column;align-items:center}.home-page__image{max-width:400px;position:relative}.home-page__image img{width:100%}.home-page__image__credit{position:absolute;bottom:0}.home-page__text{max-width:900px}.home-page__text h1{font-weight:500;text-transform:uppercase}

.review-container{width:100%;text-align:center;display:flex;flex-direction:column}.wavey-line{font-size:2rem;padding-bottom:30px}.ticker{min-height:150px;display:flex;flex-direction:column;align-items:center;text-align:center;-webkit-animation:entrance 1s ease-in-out;animation:entrance 1s ease-in-out}.ticker__quote{font-size:1.2rem;margin-bottom:10px;font-style:italic}.ticker__source{font-size:0.9rem;font-weight:bold;margin-bottom:5px}@-webkit-keyframes entrance{from{opacity:0;transform:translateY(10px)}to{opacity:1;transform:translateY(0)}}@keyframes entrance{from{opacity:0;transform:translateY(10px)}to{opacity:1;transform:translateY(0)}}

.next-show{background-color:#dacdad;padding:20px;text-align:center;display:flex;flex-direction:column;width:350px;color:#83572e}.next-show h1{text-transform:uppercase;font-weight:500}.next-show span{font-size:1.5rem}

.footer{background-color:white;color:black;display:flex;justify-content:center;padding:10px;position:relative;margin-top:60px;border-top:1px solid #ddd}

.project__top{display:flex;flex-direction:column;align-items:center;justify-content:space-evenly;position:relative}.project__youtube{width:80%;aspect-ratio:19/6;background-color:lightgray;margin:160px 0;margin:auto}.project__title{text-align:center}.project__info-container{width:100%;padding-top:90px;-webkit-animation:fadeIn 400ms;animation:fadeIn 400ms;display:flex;flex-direction:column;align-items:center}.project__text{margin:60px 0;display:flex;flex-direction:column;align-items:center !important}.project__text img{max-width:50%;padding-left:25%;padding-right:25%}.project__youtube-container{display:flex;flex-wrap:wrap;justify-content:space-evenly;padding-top:30px;border:none}.project__youtube-container iframe{border:none}.project__youtube{max-width:30%;aspect-ratio:16/9;padding:20px;background-color:white}.project__youtube--single{max-width:600px;min-width:600px;padding:10px}.project__youtube iframe{width:100%}.project__info-btn{text-decoration:none;border:2px solid rgba(50,50,50,0.3);color:#323232;width:70px;height:70px;transition:300ms;font-weight:bold;font-size:20px;display:flex;justify-content:center;align-items:center;border-radius:50%}.project__info-btn:hover{border:1px solid rgba(50,50,50,0.9)}.pdf{border:none;height:1000px;margin:auto}@media (max-width: 900px){.project__youtube{max-width:40%}.project__youtube--single{max-width:100%}}@media (max-width: 700px){.project__text img{max-width:100%;padding-left:0;padding-right:0}.project__info-container{margin-top:150px;padding-top:60px}.project__youtube{max-width:100%}.project__youtube--single{min-width:300px}}@-webkit-keyframes fadeIn{from{opacity:0}to{opacity:1}}@keyframes fadeIn{from{opacity:0}to{opacity:1}}

.carousel{display:flex;list-style:none;align-items:center;overflow:scroll;overflow-y:hidden;flex-wrap:nowrap;-webkit-scroll-snap-type:x mandatory;-ms-scroll-snap-type:x mandatory;scroll-snap-type:x mandatory;max-width:900px;max-height:calc(80 * var(--vh))}.carousel__image-wrapper{display:flex;justify-content:center}.carousel__image-wrapper img{max-height:500px;max-width:100%}.carousel__chevrons{width:100%;display:flex;justify-content:center;align-items:center}.carousel__chevrons button{background-color:transparent;border:none;font-size:larger;padding:10px 15px;margin:10px;border-radius:50%;cursor:pointer;transition:300ms;color:#323232}.carousel__chevrons button:hover{background-color:#f3f3f3}.carousel li{width:80%;min-height:1px;flex:0 0 auto;scroll-snap-align:center;transition:300ms}.carousel__item{transition:500ms;overflow:hidden;margin:0 15px}.carousel__item--pdf{border:2px solid #ddd;box-shadow:3px 3px 10px rgba(0,0,0,0.3);margin:0}.carousel__item--side{opacity:0.8;transition:500ms;cursor:pointer}.carousel__pagination{display:flex;list-style:none;padding:0;justify-content:center}.carousel__pagination__button{background-color:transparent;border:none;cursor:pointer}ul{-ms-overflow-style:none;scrollbar-width:none;overflow-y:scroll}ul::-webkit-scrollbar{display:none}@media (min-width: 900px){.carousel li{width:60%;margin:0 9%}}ul .slide:first-child{padding-left:10%}ul .slide:last-child{padding-right:10%}@media (max-width: 700px){.carousel{padding:0}.carousel li{width:100%}ul .slide:first-child{padding-left:0px}ul .slide:last-child{padding-right:0%}}

.contact-page{margin-top:200px}.contact-page a{color:#323232}.contact-page__text{font-size:25px}@media (max-width: 700px){.contact-page{margin-top:100px}}

.biog-page{max-width:1200px}.biog-page__image{width:60%;max-width:400px}.biog-page__image img{width:100%}.biog-page__texts{display:flex;justify-content:space-evenly}.biog-page__texts__biog{margin:20px;width:50%;min-width:200px}@media (max-width: 800px){.biog-page__image{width:100%}.biog-page__texts{flex-direction:column}.biog-page__texts__biog{margin:0px;width:100%}}

.reviews-page{text-align:center}.reviews-page__review{margin-bottom:20px}.reviews-page__review__text{font-size:1.3rem}


.biog-page {
  max-width: 1200px;

  &__image {
    width: 60%;
    max-width: 400px;
    & img {
      width: 100%;
    }
  }
  &__texts {
    display: flex;
    justify-content: space-evenly;

    &__biog {
      margin: 20px;
      width: 50%;
      min-width: 200px;
    }
  }
}

@media (max-width: 800px) {
  .biog-page {
    &__image {
      width: 100%;
    }
    &__texts {
      flex-direction: column;
      &__biog {
        margin: 0px;
        width: 100%;
      }
    }
  }
}

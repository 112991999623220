@import "../../variables.scss";
.contact-page {
  margin-top: 200px;

  & a {
    color: $darkGreyText;
  }

  &__text {
    font-size: 25px;
  }
}

@media (max-width: 700px) {
  .contact-page {
    margin-top: 100px;
  }
}

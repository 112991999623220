.home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__image {
    max-width: 400px;
    position: relative;
    & img {
      width: 100%;
    }

    &__credit {
      position: absolute;
      bottom: 0;
    }
  }

  &__text {
    max-width: 900px;

    h1 {
      font-weight: 500;
      text-transform: uppercase;
    }
  }
}

@import "../../variables.scss";

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  left: 0;
  right: 0;
  height: 96px;
  background-color: $darkBackgroundColour;
  z-index: 2;
  &__link {
    text-decoration: none;
    color: $darkGreyText;
    text-transform: uppercase;
    font-size: 23px;
  }
}

@media (max-width: 800px) {
  .header {
    &__link {
      font-size: 14px;
    }
  }
}

.menu-toggle {
  position: absolute;
  left: 10px;
  top: 23px;
  width: 40px;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: transparent;
  border: none;
  cursor: pointer;
  &__line {
    width: 100%;
    height: 1px;
    background-color: black;
  }
}

@media (min-width: 901px) {
  .menu-toggle {
    display: none;
  }
}

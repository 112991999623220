@import "./variables.scss";

body {
  color: $darkGreyText;
  background-color: $backgroundColour;
}
p {
  font-size: 1.1rem;
}

embed,
iframe {
  background-color: white;
}
.wrapper {
  position: relative;
}
.app-container {
  display: flex;
}

.page-container {
  flex: 1;
  margin-top: 76px;
  margin-left: 250px;
  padding: 30px;
}

.page {
  padding: 40px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeIn 1s ease-in-out;
  animation-fill-mode: both;
  min-height: 100vh;
  max-width: 1200px;
}

@media (max-width: 900px) {
  .page-container {
    margin-left: 0;
  }

  .app-container {
    display: block;
  }
}

@media (max-width: 700px) {
  .page {
    padding: 1px;
  }
}

.youtube {
  width: 80%;
  aspect-ratio: 16/9;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.review-container {
  // border-top: 1px solid grey;
  // padding-top: 30px;
  // margin-top: 30px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.wavey-line {
  font-size: 2rem;
  padding-bottom: 30px;
}

.ticker {
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  animation: entrance 1s ease-in-out;

  &__quote {
    font-size: 1.2rem;
    margin-bottom: 10px;
    font-style: italic;
  }

  &__source {
    font-size: 0.9rem;
    font-weight: bold;
    margin-bottom: 5px;
  }
}

@keyframes entrance {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@200;400;500;700&display=swap");

body {
  margin: 0;
  /* font-family: "Dosis", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
